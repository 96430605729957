require('../css/style.css');

window.addEventListener('DOMContentLoaded', run);

async function run(e) {

    document.body.addEventListener('click', e => {

        if (e.target.classList.contains('scroll-to-link')) {
            scrollTo(e);
        }
        else if (e.target.classList.contains('faq-btn') || e.target.classList.contains('faq-title')) {
            toggleFaq(e.target, {
                collapseOthers: true
            });

            if (isMobile()) {
                const toggleArea = document.querySelector('.faq-wrapper .m-slide-down');
                const wrapper = toggleArea.querySelector('.m-slide-down-measure');
                if (toggleArea.clientHeight > 0) {
                    toggleArea.style.height = wrapper.clientHeight + 'px';
                }
            }
        // } else if (e.target.classList.contains('read-more')) {
        //     toggleReadMore(e.target, {
        //         parent: '.container',
        //         area: '.slide-down',
        //         wrapper: '.slide-down-measure',
        //         text: 'self'
        //     });
        }  else if (e.target.classList.contains('read-more-2') || e.target.classList.contains('collapse-2')) {
            toggleReadMore(e.target, {
                parent: '.intro' ,
                area: '.m-slide-down',
                wrapper: '.m-slide-down-measure',
                onCollapsed: (clicked, options) => {
                    setTimeout(() => {
                        clicked.classList.remove('m-visible');
                        clicked.classList.add('m-hidden');

                        const btnExpand = clicked.closest(options.parent).querySelector('.read-more-2');
                        btnExpand.closest('.m-readmore-btn-wrapper').querySelector('.dots').textContent = '...';
                        btnExpand.classList.remove('m-hidden');
                    }, 450);
                },
                onExpanded: (clicked, options) => {
                    clicked.classList.remove('m-visible');
                    clicked.classList.add('m-hidden');
                    clicked.closest('.m-readmore-btn-wrapper').querySelector('.dots').textContent = '';
                    clicked.closest(options.parent).querySelector('.collapse-2').classList.remove('m-hidden');
                }
            })}
            else if (e.target.classList.contains('read-more-3') || e.target.classList.contains('collapse-2')) {
                toggleReadMore(e.target, {
                    parent: '.intro' ,
                    area: '.m-slide-down',
                    wrapper: '.m-slide-down-measure',
                    onCollapsed: (clicked, options) => {
                        setTimeout(() => {
                            clicked.classList.remove('m-visible');
                            clicked.classList.add('m-hidden');
    
                            const btnExpand = clicked.closest(options.parent).querySelector('.read-more-3');
                            btnExpand.closest('.m-readmore-btn-wrapper').querySelector('.dots').textContent = '...';
                            btnExpand.classList.remove('m-hidden');
                        }, 450);
                    },
                    onExpanded: (clicked, options) => {
                        clicked.classList.remove('m-visible');
                        clicked.classList.add('m-hidden');
                        clicked.closest('.m-readmore-btn-wrapper').querySelector('.dots').textContent = '';
                        clicked.closest(options.parent).querySelector('.collapse-2').classList.remove('m-hidden');
                    }
                })};
    });
}

function scrollTo(e) {

    typeof e.preventDefault === 'function' && e.preventDefault();
    const link = e.target;
    const id = link.href.slice(link.href.lastIndexOf('#') + 1);
    const element = document.getElementById(id);

    scrollToElement(element);

    if (link.dataset.tab) {
        document.getElementById(link.dataset.tab).click();
    }

    toggleMobileMenu(false);
}

function scrollToElement(element, behavior = 'smooth') {

    const scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop
    const finalOffset = element.getBoundingClientRect().top + scrollTop;

    window.scrollTo({
        top: finalOffset,
        behavior
    });
}

function toggleMobileMenu(state) {

    const hasState = typeof state !== 'undefined';
    const header = document.querySelector('.site-header');
    const condition = hasState ? !!state : !header.classList.contains('nav-visible');

    condition
        ? header.classList.add('nav-visible')
        : header.classList.remove('nav-visible');
}
function toggleFaq(faqBtn, options = {}) {

    const faq = faqBtn.closest('.faq');
    const faqs = Array.from(faq.parentElement.children);
    const isLast = faqs.indexOf(faq) === faqs.length - 1;

    if (faq.classList.contains('collapsed')) {

        if (Object(options).collapseOthers) {
            const wrapper = faqBtn.closest('.faq-wrapper');
            const faqs = wrapper.querySelectorAll('.faq');
            if (faqs.length) {
                faqs.forEach(item => {
                    item.classList.remove('expanded');
                    item.classList.add('collapsed');
                });
            }
        }

        faq.classList.remove('collapsed');
        faq.classList.add('expanded');
    } else {
        faq.classList.remove('expanded');
        faq.classList.add('collapsed');
    }

    if (isLast) {
        // faq.scrollIntoView();
        scrollToElement(faq, 'auto');
    }
}

function toggleReadMore(clicked, options) {

    const toggleArea = clicked.closest(options.parent).querySelector(options.area);

    if (toggleArea.clientHeight) {
        toggleArea.style.height = 0;
        if (options.text) {
            const text = options.textCollapsed || 'Read More';
            options.text === 'self'
                ? clicked.textContent = text
                : clicked.querySelector(options.text).textContent = text;
        }
        if (options.image) clicked.querySelector(options.image).style.transform = 'rotate(0deg)';

        if (typeof options.onCollapsed === 'function') {
            options.onCollapsed.call(null, clicked, options);
        }
    } else {

        const wrapper = toggleArea.querySelector(options.wrapper || 'div');

        // Handle any nested read mores.
        // This will trigger when device is mobile and there is nested desktop read more.
        if (Array.isArray(options.nested)) {
            options.nested.forEach(selector => {
                const nestedAreas = wrapper.querySelectorAll(selector);
                if (nestedAreas.length) {
                    nestedAreas.forEach(nestedArea => nestedArea.style.height = 'auto');
                }
            });
        }

        toggleArea.style.height = wrapper.clientHeight + 'px';

        if (options.text) {
            const text = options.textExpanded || 'Collapse';
            options.text === 'self'
                ? clicked.textContent = text
                : clicked.querySelector(options.text).textContent = text;
        }

        if (options.image) clicked.querySelector(options.image).style.transform = 'rotate(180deg)';

        if (typeof options.onExpanded === 'function') {
            options.onExpanded.call(null, clicked, options);
        }
    }
}

function isMobile() {
    const detector = document.querySelector('.mobile-detector');
    const styles = window.getComputedStyle(detector);
    return styles.getPropertyValue('display') === 'none';
}

const firstFaq = document.querySelectorAll('faq-title')[0];
console.log(firstFaq)

var swiper = new Swiper(".mySwiper", {
    slidesPerView: 4,
    centeredSlides: true,
    spaceBetween: 30,
    grabCursor: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });